
import * as __star__ from '@clayui/charts';

const {
default: __default__,
bb
} = __star__;

export {
bb
};
export default __default__;
